import React from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { generateCustomGuitarUrl } from '../../lib/generateCustomGuitarUrl';
import UnderlinedButton from '../components/Button/UnderlinedButton';
import ProductCard from '../components/ProductCard';
import ProductsGrid from '../components/ProductsGrid';
import Heading from '../components/Heading';
import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader';
import TestimonyCarouselGrid from '../components/TestimonyCarouselGrid';

const translations = [
  {
    locale: 'fr',
    url: '/guitares-personnalisees/',
  },
];

export default function CustomMadeGuitarsPage({ data }) {
  let {
    guitars: { nodes: guitars = [] },
  } = data;

  guitars = guitars.map((guitar) => ({
    name: guitar.name,
    image: {
      gatsbyImageData: guitar.featuredImage.asset.localFile.childImageSharp,
    },
    href: generateCustomGuitarUrl('en', guitar.slug.current),
  }));

  return (
    <Layout translations={translations}>
      <article className="space-y-48">
        <PageHeader>
          Custom-made <br />
          guitars
        </PageHeader>

        <section className="container">
          <Heading level={2}>The processus</Heading>

          <div className="lg:columns-2">
            <Heading level={3} className="!mt-0">
              Creating your dream instrument: the process from A to Z
            </Heading>
            <div className="space-y-4">
              <p>
                It all starts with a 2-hour meeting with Pellerin at his
                workshop. In the middle of stacks of rosewood and Sitka spruce,
                you can chat about what makes you tick and exchange stories
                about shows you have done. You can also talk about yourself and
                share your passion for music and beautiful things.
              </p>

              <p>
                Then you will get to the heart of the matter: the type of wood
                you prefer for your dream instrument, and the shape and look
                your guitar will have. You will discuss the sound you are
                looking for. This will allow Pellerin to transpose your
                personality into your guitar.
              </p>

              <p>
                Next, the creation begins as Pellerin puts his expertise to
                work. Throughout the entire process, he will send you photos of
                the work in progress. Depending on the complexity of the
                project, Pellerin will invest 150 to 200 hours in making your
                custom guitar.
              </p>
            </div>

            <Heading level={3}>
              You will say “Wow!” before your first riff
            </Heading>
            <div className="space-y-4">
              <p>
                Not only will you never forget your first Pellerin guitar, but
                you will want a second.
              </p>

              <p>
                Because Michel Pellerin knows how to make the perfect guitar for
                both your eyes and ears.
              </p>

              <p>
                Whether it be the wood, the neck, the colour, the varnish or a
                specific feature, every part of your instrument can be
                customized. If you are concerned about forest conservation, you
                have the option of choosing salvaged wood. Your personality and
                your own tastes will guide Pellerin as he prepares his
                masterpiece for you. With all those details in mind, he will
                make that unique guitar just for you.
              </p>

              <p>
                Once Pellerin has finished the job, he will leave his little
                corner of Quebec to deliver the masterpiece to you in person.
                You will receive your instrument in a fine case that you can
                also have customized. Pellerin will be there with you for the
                last step of this exciting adventure. He will make the final
                adjustments to make sure that the sound of your Pellerin guitar
                is just right for you.
              </p>
            </div>
          </div>
        </section>

        <StaticImage
          src="../images/PellerinGuitares_148.jpeg"
          alt=""
          role="presentation"
          className="w-screen"
        />

        <section className="container space-y-24">
          <div className="mx-auto max-w-prose text-center">
            <Heading level={3}>
              An international sound; a Quebecer’s expertise
            </Heading>

            <p>
              There are countless ways to express how pure the Pellerin sound
              is. Some say their guitar sings, while others compare it to a
              grand piano. One thing is for sure: you will never hear a guitar
              with such a rich and clear sound. Whether in the bass, midrange or
              treble, the resonance amazes even the most experienced players.
              Your Pellerin guitar will get your blood pumping.
            </p>
          </div>

          {/* <div>
            <WorldMap className="max-w-full" />
          </div> */}
        </section>

        <section className="container">
          <Heading level={2}>See Pellerin guitars</Heading>

          <ProductsGrid>
            {guitars.map((guitar) => (
              <ProductCard
                key={guitar.href}
                name={guitar.name}
                href={guitar.href}
                image={guitar.image}
              />
            ))}
          </ProductsGrid>
        </section>

        <section>
          <div className="container text-xl text-center">
            <Heading level={2}>Rave reviews of Pellerin guitars</Heading>
          </div>

          <TestimonyCarouselGrid className="my-12" />

          <div className="container text-center">
            <UnderlinedButton to="/en/testimonials/" className="text-xl my-5">
              Read what customers have said
            </UnderlinedButton>
          </div>
        </section>
      </article>
    </Layout>
  );
}

export const query = graphql`
  query {
    centralImage: file(name: { eq: "PellerinGuitares_148" }) {
      childImageSharp {
        gatsbyImageData(width: 1536, placeholder: BLURRED)
      }
    }
    guitars: allSanityGuitarModel(sort: { fields: orderRank, order: ASC }) {
      nodes {
        slug {
          current
        }
        name
        featuredImage {
          asset {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 744, height: 1044, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`;
